@mixin grid-cols($default, $split: null, $full: null) {
  @if $default {
    @include grid-col($default);
  }
  @if $split {
    @include at-media($theme-site-cols-split) {
      @include grid-col($split);
    }
  }
  @if $full {
    @include at-media($theme-site-cols-show) {
      @include grid-col($full);
    }
  }
}

@mixin usa-grid-container {
  @include grid-container;
}

@mixin usa-grid-row {
  @include grid-row;
  @include u-flex("column", "align-stretch");
  @include at-media($theme-site-cols-split) {
    @include grid-gap($theme-grid-gap-default);
    @include u-flex("row");
  }
}

@mixin usa-grid-col {
  @include grid-cols("auto", 6);
  width: 100%;
}
