@use "sass:math";

@import "mixins";

@import "custom-styles/site-header";
@import "custom-styles/card-policy";
@import "custom-styles/highlight";
@import "custom-styles/copy-code-btn";
@import "custom-styles/graphic-list";
@import "custom-styles/posts";

// ========================================
// CUSTOM PROJECT SASS
// ----------------------------------------
// Custom project SASS goes here.
//
// Contents
// 1. Generic
// 2. Site layout
// 3. Custom page styles
// ----------------------------------------

// TODO: move to uswds once finished
$theme-site-cols-split: "tablet";
$theme-site-cols-show: "desktop";
$theme-grid-gap-default: "md";

$site-measure: 5;

.usa-grid-container {
  @include usa-grid-container;
}

.usa-grid-row {
  @include usa-grid-row;
}

.usa-grid-col {
  @include usa-grid-col;
}

// Specific grid-col styes added for use in the card component grid
/* stylelint-disable */
@media all and (min-width: 55em) {
  .tablet-lg\:grid-col-6 {
    flex: 0 1 auto !important;
    width: 50% !important;
  }
}

@media all and (min-width: 87.5em) {
  .widescreen\:grid-col-4 {
    flex: 0 1 auto !important;
    width: 33.3333333333% !important;
  }
}
/* stylelint-enable */

// ========================================
// 1. Generic
// ----------------------------------------
.default-container {
  @include u-bg($site-background-color);
  margin-left: auto;
  margin-right: auto;
  max-width: units("widescreen");
}

html,
body {
  @include typeset("lang");
  height: auto;
  color: color($site-ink);
}

img {
  height: auto;
}

.layout-styleguide {
  @include u-bg($site-background-color);
}

.usa-center {
  text-align: center;
}

.usa-prose .site-prose {
  @include typeset("lang");
}

abbr[title="required"] {
  text-decoration: none;
}

// ========================================
// 2. Site layout
// ----------------------------------------

// Header ------------------------------ //

.sticky {
  @include at-media("desktop") {
    @include u-z(200);
    position: sticky;
    top: 0;
  }
}

.site-banner {
  $site-banner-font-family: "lang";
  $site-banner-icon-color: "blue-40v";
  $site-banner-action-color: "gray-20";
  @include typeset($site-banner-font-family);
  @include at-media("tablet") {
    font-size: font-size($site-banner-font-family, "3xs");
  }
  background-color: color("ink");

  .usa-accordion {
    @include typeset($site-banner-font-family);
  }

  .usa-banner__button {
    color: color($site-banner-action-color);
    font-size: font-size($site-banner-font-family, 1);
    line-height: line-height($site-banner-font-family, 2);
    &:hover {
      color: color("white");
      &::after {
        background-color: color($site-banner-icon-color);
      }
    }
    &::after {
      background-color: color($site-banner-icon-color);
    }
  }

  .usa-banner__content {
    color: color("white");
    font-size: font-size($site-banner-font-family, 4);
  }

  .usa-banner__header {
    font-size: font-size($site-banner-font-family, 1);
  }

  .usa-banner__header--expanded {
    @include at-media("tablet") {
      font-size: font-size($site-banner-font-family, 1);
    }
  }

  .usa-banner__header-action {
    color: color($site-banner-action-color);
    line-height: line-height($site-banner-font-family, 2);
  }

  .usa-banner__header-text {
    @include u-margin-y(0);
    color: color("white");
    font-size: font-size($site-banner-font-family, 1);
    line-height: line-height($site-banner-font-family, 2);
  }
}

// Sidebar Nav --------- //

.site-sidenav.usa-sidenav {
  @include typeset("lang", "xs", 3);
  background-color: color("white");
  border: units($site-border-width) solid color("black-transparent-10");
  border-radius: radius("md");
  padding: 0;

  a {
    @include u-padding-x(2);
    color: color("base-darker");

    &:hover {
      background-color: transparent;
      color: color($site-primary-vivid);
    }
  }

  .usa-current {
    color: color("base-darker");
    position: static;

    &::after {
      border-radius: 0;
      background-color: color($site-primary-vivid);
      bottom: units(-1px);
      left: units($site-border-width) * -1;
      top: units(0);
      z-index: z(100);
    }
  }

  .usa-sidenav__item:first-child {
    .usa-current::after {
      border-top-left-radius: radius("md");
      top: units($site-border-width) * -1;
    }
  }

  .usa-sidenav__item:last-child {
    .usa-current::after {
      border-bottom-left-radius: radius("md");
      bottom: units($site-border-width) * -1;
    }
  }

  .usa-sidenav__item {
    border-top: none;
    line-height: lh("lang", 3);
    position: relative;

    & + .usa-sidenav__item {
      a {
        // TODO: use 'black-transparent-5' when value changes
        border-top: units(1px) solid rgba(0, 0, 0, 0.05);
      }
    }

    &.is-current {
      background-color: color($site-background-color);

      .is-current {
        background-color: transparent;
      }
    }
  }

  .usa-sidenav__sublist {
    font-size: font-size("lang", "3xs");
    a {
      border-top: units(1px) solid rgba(0, 0, 0, 0.05);
      padding-left: units(2);
    }

    .usa-current {
      color: color($site-primary-vivid);
    }

    .usa-sidenav__sublist {
      a {
        padding-left: units(4);
      }
    }
  }
}

.sidenav {
  display: none;

  @include at-media("desktop") {
    display: block;
    float: left;
    margin-bottom: units(6);
    margin-top: units(4);
    padding-right: 0;
    padding-left: units(4);
    width: units($width-nav-sidebar);
    top: 4em;
  }

  .usa-navigation-submenu {
    &[aria-hidden="false"] {
      display: block;
    }
  }
}

// Main Content --------- //

.main-content,
.usa-in-page-nav-container main {
  @include u-pin("right");
  @include u-pin("bottom");
  display: inline-block; // starting: not scrolled
  margin-top: units(5); // starting: not scrolled
  position: relative;
  width: 100%;

  @include at-media("desktop") {
    width: calc(100% - #{units($width-nav-sidebar)});
  }

  .lt-ie9 & {
    width: 75%;
  }
}

.site-h2,
%site-h2 {
  @include typeset("lang", "xl", 2);
  color: color($site-ink);
  margin-top: units(4);

  & + p {
    margin-top: units(1.5);
  }
}

.site-figure {
  @include u-margin(0);
}

.site-figcaption {
  @include u-font-size("lang", "2xs");
  @include u-margin-y(2);
  color: color($site-ink);
}

// Styleguide Content -------- //

.styleguide-content {
  @include add-responsive-site-margins;
  margin-bottom: units(6);
  max-width: units($theme-grid-container-max-width);
  position: relative;

  header {
    > h1 {
      margin-top: 0;
      margin-bottom: 0;
    }

    & + p {
      margin-top: units(1.5);
    }
  }

  > h2 {
    @extend %site-h2;
  }

  > h3 {
    @include typeset("lang", "md", 3);

    & + p {
      margin-top: units(1);
    }
  }

  > h4 {
    @include typeset("lang", "2xs", 3);
    @include u-text("uppercase", $site-ink, "normal", "ls-2");
    margin-top: units(3);

    & + p,
    & + ol,
    & + ul {
      margin-top: units(1);
    }
  }

  > h5 {
    @include typeset("lang", "2xs", 3);
    @include u-text($site-ink, "bold");
    margin-top: units(2);

    & + p,
    & + ol,
    & + ul {
      border-top: units(1px) solid color("base-lighter");
      padding-top: units(1);
      margin-top: units(0.5);
    }
  }

  .usa-list {
    max-width: measure($site-measure);
  }
}

.usa-prose > .usa-button-group {
  padding-left: 0;
}

.product-roadmap-list {
  li {
    max-width: 100%;
  }
}

#known-issues {
  scroll-margin-top: 2.75rem;
}

// Label links

%label-maturity {
  @include typeset("lang", "2xs", 4);
  color: color("ink");
  display: inline-block;
  font-weight: font-weight("bold");
  margin-left: units(1.5);
  position: absolute;
  text-decoration: none;

  &#{$external-href}::after {
    display: none;
  }
}

.label-alpha,
.label-in-progress {
  @extend %label-maturity;
  background-color: color("gold-20v");
}

.label-beta,
.label-next {
  @extend %label-maturity;
  background-color: color("primary-vivid");
  color: color("white");
}

.label-upcoming {
  @extend %label-maturity;
  @include u-text("ls-1", "normal");
  @include u-border("base-light", 1px);
  color: color("base-dark");
  background-color: color("transparent");
}

.label-recommended,
.label-done {
  @extend %label-maturity;
  background-color: color("green-cool-20v");
}

.label-cancelled {
  @extend %label-maturity;
  background-color: color("red-50v");
  color: color("white");
}

.label-archived {
  @extend %label-maturity;
  @include set-text-and-bg("red-30");
}

.label-stale {
  @extend %label-maturity;
  @include set-text-and-bg("yellow-20v");
}

.heading-margin-alt {
  margin-top: units(7);
}

.site-subheading {
  @include typeset-h6;
  @include u-font("lang", "3xs");
  color: color("black-transparent-60");
  letter-spacing: letter-spacing(1);
  margin-bottom: units(1);
  margin-top: 0;
  text-transform: uppercase;
}

.cta {
  img {
    height: units(2);
    margin-right: units(0.5);
    position: relative;
    top: units(2px);
    vertical-align: baseline;
  }
}

// Footer --------------- //

.touchpoints__container {
  padding-left: units(2);
  padding-right: units(2);
  @include at-media("desktop") {
    display: flex;
    justify-content: flex-end;
  }
  @include at-media("widescreen") {
    bottom: 0;
    position: sticky;
    z-index: z-index(500);
    pointer-events: none;
  }

  .usa-button {
    margin-bottom: units(2);
    @include at-media("desktop") {
      @include u-bg($site-background-color);
      margin: units(2) units(2) units(4);
    }

    @include at-media("widescreen") {
      pointer-events: auto;
    }

    &#{$external-href} {
      display: inline-block;
    }
  }
}

.site-footer {
  @include typeset("lang");
  clear: both;

  h3 {
    margin-top: 0;
  }

  .logo-links {
    margin-bottom: units(1);
    margin-right: units(2.5);

    @include at-media("tablet") {
      float: left;
      margin-bottom: 0;
    }

    img {
      width: units(4);
    }

    a {
      @include media-link;
    }
  }

  .usa-footer__secondary-section {
    @include u-padding-bottom(0);
    @include u-padding-top(2);
    background-color: color("base-lightest");

    @include at-media("tablet") {
      padding-top: units(4);
    }
  }
}

.footer-main-img {
  display: none;
  max-width: units("card");

  @include at-media("tablet") {
    display: block;
    margin: 0;
    width: 100%;
  }
}

.footer-content {
  margin-bottom: units(5);

  h2 {
    @include typeset("lang", "lg", 2);
    margin: 0;
  }
  p {
    max-width: measure($site-measure);
  }
}

.footer-contact-links {
  @include grid-gap(2);

  h3,
  a {
    @include u-font("lang", "2xs");
  }

  h3 {
    font-weight: font-weight("normal");
    margin-bottom: 0;
    margin-top: units(2px);
  }

  a {
    color: color($site-primary-dark);
    text-decoration: underline;
  }

  img {
    width: units(5);
  }

  .usa-media-block {
    clear: both;
    line-height: line-height("lang", 3);
    margin-top: units(2.5);

    @include at-media("tablet") {
      margin-top: 0;
    }
  }

  @include at-media("tablet") {
    margin-top: units(4);
  }
}

// Pattern Preview Boxes -------- //

.preview {
  @include clearfix;
  @include u-margin-y(2);
  background-color: color("white");
  padding: units(2);
  border: units(1px) solid color("base-lightest");

  .usa-dark-background {
    @include u-padding-x(1);
    display: inline-block;
  }

  > *:first-child {
    margin-top: 0;
  }
}

// Custom preview box styles

.preview-lists {
  .usa-heading-alt {
    margin-top: 0;
  }
}

.preview-dropdown {
  padding-top: 0;
}

// Custom styles to illustrate invisible grid for web design standards

.page-grids {
  .preview {
    border: 0;
    margin-bottom: units(2.5);
    margin-top: 0;
    padding: 0;

    > *:first-child {
      margin-top: units(2);
    }
  }
}

.grid-example {
  .usa-grid {
    background: color($color-grid-light);

    > * {
      @include u-margin-y(0.5);
      color: color("white");
      padding: units(1);
      background: color($color-grid-dark);
    }
  }

  .usa-width-one-twelfth {
    @include at-media("tablet") {
      @include u-padding-x(0);
    }
  }
}

.grid-example-blank {
  .usa-grid {
    text-align: center;
  }
}

.grid-text {
  .usa-grid {
    background: color($color-grid-dark);

    > * {
      background: color($color-grid-light);
      color: color("black");
    }

    h3 {
      margin-top: 0;
    }

    p {
      margin-bottom: 0;
    }
  }
}

.usa-heading {
  color: color($site-primary-dark);
}

// Color background helpers

.usa-color-primary {
  background-color: color($site-primary);
}

.usa-color-primary-darker {
  background-color: color($site-primary-dark);
}

.usa-color-primary-darkest {
  background-color: color($site-primary-darker);
}

.usa-color-base {
  background-color: color("base-darkest");
}

.usa-color-gray-dark {
  background-color: color("base-darker");
}

.usa-color-gray-light {
  background-color: color("base-light");
}

.usa-color-white {
  background-color: color("white");
  box-shadow: inset 0 0 0 1px color("gray-cool-60");
}

.usa-color-primary-alt {
  background-color: color("accent-cool");
}

.usa-color-primary-alt-dark {
  background-color: color("accent-cool-dark");
}

.usa-color-primary-alt-darkest {
  background-color: color("accent-cool-darker");
}

.usa-color-primary-alt-light {
  background-color: color("accent-cool-light");
}

.usa-color-primary-alt-lightest {
  background-color: color("accent-cool-lighter");
}

.usa-color-secondary {
  background-color: color("secondary");
}

.usa-color-secondary-dark {
  background-color: color("secondary-dark");
}

.usa-color-secondary-darkest {
  background-color: color("secondary-darker");
}

.usa-color-secondary-light {
  background-color: color("secondary-light");
}

.usa-color-secondary-lightest {
  background-color: color("secondary-lighter");
}

.usa-color-gray {
  background-color: color("gray-cool-60");
}

.usa-color-gray-lighter {
  background-color: color("gray-cool-10");
}

.usa-color-gray-lightest {
  background-color: color("gray-cool-5");
}

.usa-color-gray-warm-dark {
  background-color: color("gray-warm-70");
}

.usa-color-gray-warm-light {
  background-color: color("gray-warm-10");
}

.usa-color-gray-cool-light {
  background-color: color("blue-warm-5");
}

.usa-color-gold {
  background-color: color("gold-20v");
}

.usa-color-gold-light {
  background-color: color("gold-10v");
}

.usa-color-gold-lighter {
  background-color: color("yellow-10");
}

.usa-color-gold-lightest {
  background-color: color("gold-5v");
}

.usa-color-green {
  background-color: color("green-cool-50");
}

.usa-color-green-light {
  background-color: color("green-cool-40");
}

.usa-color-green-lighter {
  background-color: color("green-cool-20");
}

.usa-color-green-lightest {
  background-color: color("green-cool-5");
}

.usa-color-cool-blue {
  background-color: color("blue-70v");
}

.usa-color-cool-blue-light {
  background-color: color("blue-warm-50");
}

.usa-color-cool-blue-lighter {
  background-color: color("blue-warm-30");
}

.usa-color-cool-blue-lightest {
  background-color: color("blue-warm-10");
}

.usa-color-focus {
  background-color: color($theme-focus-color);
}

.usa-color-visited {
  background-color: color($theme-link-visited-color);
}

// Color text helpers

.usa-color-text-primary-darkest {
  color: color($site-primary-darker);
}

.usa-color-text-primary-darker {
  color: color($site-primary-dark);
}

.usa-color-text-primary {
  color: color($site-primary);
}

.usa-color-text-cool-blue-light {
  color: color("blue-warm-50");
}

.usa-color-text-primary-alt-darkest {
  color: color("accent-cool-darker");
}

.usa-color-text-green {
  color: color("green-cool-50");
}

.usa-color-text-visited {
  color: color($theme-link-visited-color);
}

.usa-color-text-gray-dark {
  color: color("gray-cool-80");
}

.usa-color-text-gray {
  color: color("gray-cool-60");
}

.usa-color-text-gray-warm-dark {
  color: color("gray-warm-70");
}

.usa-color-text-secondary-darkest {
  color: color("secondary-darker");
}

.usa-color-text-secondary-dark {
  color: color("secondary-dark");
}

.usa-color-text-secondary {
  color: color("secondary");
}

.usa-color-text-white {
  color: color("white");
}

.usa-heading-alt {
  border-bottom: units(1px) solid color("gray-cool-60");
  padding-bottom: units(1);
}

.usa-typography-example-font {
  padding-top: units(1);

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .usa-font-example p {
    margin: 0;
  }
}

.font-example-paragraph {
  @include at-media("tablet") {
    max-width: units("card-lg");
    width: 100%;
  }
}

.usa-typography-example {
  .usa-monospace {
    font-size: font-size("mono", "3xs");
    margin-bottom: units(4);
    margin-top: 0;
  }
}

.usa-monospace {
  font-family: font-family("mono");
}

// Code samples ---------------------- //

code,
pre {
  @include u-font("mono", "3xs");
  // TODO: add more black-transparent values (1-5)
  background-color: rgba(0, 0, 0, 0.05);
  font-weight: fw("normal");
}

pre {
  padding: units(2);

  code {
    padding: 0;
    background-color: transparent;

    &::before,
    &::after {
      display: none;
    }
  }

  &.lineno {
    user-select: none;
  }
}

a.token {
  @include u-padding-x(0.5);
  @include u-padding-y("05");
  @include u-font("code", "3xs");
  @include u-text("no-underline");
  background-color: color($site-primary-lighter);
  border: units("1px") solid color($site-primary-lighter);
  border-radius: radius("sm");
  color: color($site-ink);
  display: inline-block;
  line-height: line-height("mono", 1);

  &:visited {
    color: color($site-ink);
  }

  &:hover,
  &:hover:visited {
    background-color: color($site-primary-vivid);
    border-color: color($site-primary-vivid);
    color: color("white");
  }

  &:active,
  &:active:visited {
    background-color: color($site-ink);
    border-color: color($site-ink);
    color: color("white");
  }
}

code {
  @include u-padding-y(2px);

  &::before,
  &::after {
    letter-spacing: letter-spacing("ls-neg-3");
    content: "\00a0";
  }
}

// Code snippet boxes ----------------- //

.site-accordion-code {
  margin-bottom: units(1);

  pre,
  code {
    @include typeset("mono", "2xs", 4);
    background: none;
    margin-bottom: 0;
    margin-top: 0;
    padding: 0;
  }

  .usa-unstyled-list {
    @include unstyled-list;
  }
}

code[class*="language-"] {
  color: color("base-dark");

  // Custom code sample colors
  .token.property,
  .token.tag,
  .token.boolean,
  .token.number,
  .token.constant,
  .token.symbol,
  .token.deleted {
    color: color("accent-cool-darker");
  }

  .na,
  .token.selector,
  .token.attr-name,
  .token.string,
  .token.char,
  .token.builtin,
  .token.inserted {
    color: color("secondary");
  }

  .token.atrule,
  .token.attr-value,
  .token.keyword {
    color: color("green-cool-50");
  }

  .token.punctuation {
    color: color("accent-cool-darker");
  }

  .c {
    color: color("gray-cool-60");
  }

  .nt {
    color: color("primary-dark");
  }

  .s {
    color: color("primary-vivid");
  }
}

code[class*="language-plaintext"] {
  color: inherit;
}

.alignment-example {
  border-left: units(2px) solid color($site-primary-dark);
  padding-left: units(1);
}

.help-text {
  border-top: units(2px) solid color($site-primary-dark);
  color: color($site-primary-dark);
  font-size: $theme-root-font-size;
  padding-top: units(1);
  text-align: center;
}

.text-tiny {
  margin-bottom: 0;
  margin-top: units(0.5);

  &:first-child {
    margin-top: 0;
  }
}

.typography-sans-intro {
  .text-huge {
    @include typeset("sans", 20, 1);
  }

  .text-tiny {
    @include typeset("sans", "2xs", 1);
  }
}

.typography-serif-intro {
  .text-huge {
    @include typeset("serif", 20, 1);
  }

  .text-tiny {
    @include typeset("serif", "2xs", 1);
  }
}

.typography-lang-intro {
  .text-huge {
    @include typeset("lang", 20, 1);
  }

  .text-tiny {
    @include typeset("lang", "2xs", 1);
  }
}

.typography-mono-intro {
  .text-huge {
    @include typeset("mono", 20, 1);
  }

  .text-tiny {
    @include typeset("mono", "2xs", 1);
  }
}

// Alt font styles

.serif-robust {
  .usa-display-alt {
    font-size: font-size("lang", $theme-h1-font-size);
    font-weight: font-weight("light");
  }

  .usa-font-lead {
    font-weight: font-weight("light");

    .usa-font-lead-alt {
      @include font-lead-alt;
    }
  }

  &.serif-body {
    .usa-font-example {
      p {
        @include typeset("serif", "xs", 3);
      }

      // scss-lint:disable SelectorDepth, NestingDepth
      .usa-font-lead {
        font-size: font-size("lang", "lg");

        .usa-font-lead-alt {
          @include font-lead-alt;
        }
      }
    }
  }
}

.sans-style {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: font-family("sans");
  }

  h1 {
    font-size: font-size("sans", 15);
  }

  h2 {
    font-size: font-size("sans", 13);
  }

  h3 {
    font-size: font-size("sans", 11);
  }

  h4 {
    font-size: font-size("sans", 8);
  }

  h5 {
    font-size: font-size("sans", 6);
  }

  h6 {
    font-size: font-size("sans", 3);
  }

  .usa-display {
    font-size: font-size("sans", 16);
  }

  .usa-display-alt {
    font-size: font-size("sans", 15);
    font-weight: font-weight("light");
  }

  .usa-font-lead {
    @include typeset("sans", 10, 4);
    font-weight: font-weight("light");
  }

  &.serif-body {
    .usa-font-example {
      p {
        @include typeset("serif", 5, 3);
      }

      .usa-font-lead {
        @include typeset("sans", 8, 4);
        font-weight: font-weight("light");
      }
    }
  }
}

.serif-sans-minor {
  h6 {
    font-family: font-family("sans");
  }
}

.site-accordion-docs {
  margin-bottom: units(7);
}

.site-component-preview {
  h5 {
    @include typeset("lang", "sm", 3);
    @include u-text($site-ink, "bold");
    margin-top: units(4);
    margin-bottom: units(1);

    & + form fieldset legend,
    & + label {
      margin-top: 0;
    }
  }

  h4 + h5 {
    margin-top: units(2);
  }
}

// External link styles

#{$external-href} {
  @include external-link;

  &.usa-button {
    display: inline-block;
  }
}

.usa-link--alt#{$external-href} {
  @include external-link;
}

.site-button-github,
.link-download,
.media__link {
  &#{$external-href}::after {
    display: none;
  }
}

.logo-links,
.usa-disclaimer-stage {
  #{$external-href} {
    &::after {
      display: none;
    }
  }
}

// ========================================
// 3. Custom page styles
// ----------------------------------------

// Homepage styles --------------------- //

.page-home {
  .usa-graphic-list {
    .usa-media-block {
      @include at-media("desktop") {
        @include u-flex("column");
      }
    }

    .usa-media-block__body {
      // scss-lint:disable SelectorDepth
      > *:first-child {
        @include u-font("lang", $media-block-header-font-size);
        margin-top: 0;
        margin-bottom: 0;
      }

      > p {
        @include u-font("lang", $media-block-body-font-size);
        margin: units(0.5) 0;
      }

      // IE11 overlap issue.
      @include at-media("desktop") {
        width: 100%;
        flex: 0 0 auto;
      }
    }

    .usa-media-block__img {
      margin-bottom: units(2);
      margin-right: units(2.5);
      max-width: units(9);
    }
  }

  // Override for 4x1 media grid when it collapses to 2x2
  @media screen and (max-width: units("desktop")) and (min-width: units("tablet")) {
    // scss-lint:disable SelectorDepth
    .usa-graphic-list
      .usa-graphic-list__row
      .usa-media-block:nth-child(-n + 2) {
      margin-bottom: units(8);
    }
  }

  .usa-footer__secondary-section {
    background-color: color("base-lighter");
  }
}

.hero {
  @include at-media("tablet-lg") {
    background-image: url("#{$site-image-path}/home/hero.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right units(5) center;
  }
}

.hero--heading {
  font-size: font-size("lang", "xl");

  @include at-media("tablet") {
    font-size: font-size("lang", "2xl");
  }
}

.hero--content {
  font-size: font-size("lang", "lg");
}

.showcase {
  img {
    @include u-shadow(1);
  }

  a {
    display: inline-block;
    font-size: font-size("lang", "md");
    font-weight: fw("bold");
    margin-bottom: units(4);
    margin-top: units(1);

    @include at-media("tablet") {
      margin-bottom: 0;
    }
  }
}

.font-display {
  font-size: font-size("lang", "3xl");
}

// Fancy Horizontal Rule

.homepage-rule {
  @include u-margin-y(0);
  border: 0;
  height: units(2);
  width: 100%;
  position: relative;

  &.center-diamond {
    background: none;
    text-align: center;

    &::before {
      content: "\00a0";
      display: inline-block;
      background: color($site-primary);
      height: units(1px);
      position: relative;
      top: units(1);
      width: 100%;
    }

    &::after {
      content: "◆";
      display: inline-block;
      position: relative;
      top: units(-4);
      font-size: 1.125rem; // TODO: do I care about this? (18px)
      padding: 0 units(1);
      color: color("secondary");
      background: color($site-background-color);
    }
  }
}

.page-ui-components {
  .usa-font-lead {
    &:first-of-type {
      margin-top: units(4);
    }
  }
}

.page-design-principles {
  @include at-media("tablet") {
    .main-content .usa-grid-full {
      margin-bottom: units(4);
      margin-top: units(5);
    }
  }

  img {
    max-width: units("card");
  }
}

.link-download-subtext {
  margin-left: units(3);
}

.link__group-download {
  .link-download {
    @include u-measure(5);
    align-items: center;
    background-color: color("blue-warm-5");
    border-radius: radius("md");
    display: flex;
    padding: units(2);
  }

  .usa-icon {
    height: units(3);
    margin-right: units(1);
    width: units(3);
  }
}

.link__group-download {
  @include u-margin-y(2);

  .link-download {
    margin-bottom: units(1);
  }
}

.page-product-roadmap,
.page-for-developers,
.page-for-designers,
.page-component-maturity-scale {
  .usa-content {
    > ul:not(.usa-content-list) {
      max-width: measure(3);
    }
  }
}

.page-product-roadmap {
  .main-content {
    .usa-tag {
      position: relative;
    }
  }
}

// Layout grid page
.docs-grid-example {
  @include u-font("mono", 4);

  .grid-row {
    margin-top: units(1);
  }

  [class*="grid-col"] {
    background-color: color($site-primary-lighter);
    text-align: center;
  }

  .grid-row:not([class*="grid-gap"]) {
    [class*="grid-col"] {
      border: units(1px) solid;
      padding: units(2);
    }
  }

  // scss-lint:disable SelectorDepth
  [class*="grid-gap"] {
    [class*="grid-col"] > * {
      background-color: color("white");
      border: units(1px) solid;
      padding: units(2);
    }
  }
}

// Example images on headers and page templates pages -------------- //
.page-header,
.page-page-templates {
  .preview {
    img {
      @include u-shadow(2);
      margin-bottom: units(2);
    }
  }
}

.layout-demo {
  margin-bottom: units(10);

  @include at-media("tablet") {
    margin-bottom: units(8);
  }
}

// Demo fixed footer
// Example url: `/templates/docs`
.demo-footer {
  @include u-padding-y(1);
  background-color: color("gray-cool-80");
  // TODO: figure this out
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.5); // scss-lint:disable ColorVariable
  bottom: 0;
  position: fixed;
  width: 100%;

  .usa-button {
    background-image: url("#{$site-image-path}/angle-arrow-left-inverse.png");
    background-image: url("#{$site-image-path}/angle-arrow-left-inverse.svg");
    background-repeat: no-repeat;
    background-position: left units(2.5) center;
    background-size: units(1);
    padding-left: units(4);

    @include at-media("tablet") {
      margin-right: units(2);
      margin-top: 0;
    }
  }
}

.demo-footer-title {
  margin-bottom: units(1);
  margin-top: 0;
  color: color("white");
  display: inline-block;

  @include at-media("tablet") {
    @include u-margin-y(1);
  }
}

.demo-footer-content {
  @include add-responsive-site-margins;
}

hr {
  background: color("gray-cool-10");
  border: none;
  height: units(1px);
  margin: units(4) 0;
}

iframe {
  margin-bottom: units(4);
}

// Utilities CSS --------------------- //

.page-content {
  h3 {
    @include u-font-size("lang", 10);
    @include u-text("normal");
    @include u-line-height("lang", 3);
    @include at-media("tablet-lg") {
      @include u-font-size("lang", 15);
    }
  }
}

.docs-text {
  @include u-font("lang", 6);
  @include u-margin-bottom(1);
  @include u-margin-top(4);
  @include u-measure($site-measure);
  @include u-text("normal");
}

strong {
  @include u-text("bold");
}

.docs-inline-code {
  @include u-bg("gray-warm-4");
  @include u-font("mono", 4);
  @include u-padding-x("05");
  @include u-padding-y(2px);
  @include u-radius("sm");
}

.property {
  $font: "mono";
  @include u-border(1px);
  @include u-color("red-warm-50v");
  @include u-font($font, 1);
  @include u-line-height($font, 1);
  @include u-padding-x("05");
  @include u-padding-y(2px);
  @include u-radius("sm");
  @include u-text(
    "normal",
    "no-wrap",
    "no-underline",
    "uppercase",
    "no-italic",
    "ls-2"
  );
}

.prose-graf-intro {
  $font: "lang";
  @include u-font($font, 2);
  @include u-text("bold", "uppercase", "ls-2");
  @include at-media("desktop-lg") {
    @include u-font-size($font, 3);
  }
}

.text-color .example {
  @include u-border-bottom(1px, "base-lighter");
  @include u-margin-y(0.5);
  @include u-padding-y(0.5);
}

.output-list {
  @include grid-row;
  @include u-margin-top(0);
  @include u-width("full");

  .output-utility,
  .output-css,
  .output-variable {
    @include u-border-bottom(1px);
    @include u-border-bottom("base-light");
    @include u-display(flex);
    @include u-flex("align-center");
    @include u-margin(0);
    @include u-minh(4);
    @include u-padding-y(0.5);
  }

  .output-utility {
    @include grid-col(4);
    @include u-font("lang", "2xs");
    @include u-padding-right(2);
  }

  .output-css {
    @include grid-col(6);
    @include u-font("mono", "2xs");
    @include u-padding-right(2);

    .output-token {
      @include u-display(inline-block);
      @include u-font("mono", "2xs");
    }
  }

  .output-variable {
    @include grid-col(2);
    @include u-font("mono", "2xs");
    @include u-text("light");
  }
}

.output-token {
  @include u-bg("white");
  @include u-border(1px, "gray-10");
  @include u-font("mono", 4);
  @include u-padding-x(0.5);
  @include u-padding-y(2px);
  @include u-radius("sm");
  @include u-text("normal");
}

.output-rule {
  @include u-display(block);

  + .output-rule {
    @include u-margin-top(0.5);
  }
}

.output-true,
%output-true {
  @include u-bg("blue-50v");
  @include u-display("inline-block");
  @include u-padding-y(0);
  @include u-padding-x(0.5);
  @include u-radius("sm");
  @include u-text("white");
}

.output-false {
  @extend %output-true;
  @include u-bg("error");
}

.output-warning {
  @extend %output-true;
  @include u-bg("warning");
  @include u-text("ink");
}

.output-neutral {
  @extend %output-true;
  @include u-bg("base-lighter");
  @include u-text("ink");
}

li + li .output-neutral,
li + li .output-warning {
  margin-top: units(0.5);
}

// Ensure that inline lists do not have a bottom margin
li {
  &.display-inline-block {
    margin-bottom: 0;
  }
}

.page-introducing-uswds-2-0 {
  .post-content {
    img {
      @include u-maxw(mobile);
      mix-blend-mode: darken;
    }
  }
}

// * * * * * * ==========================
// Utility docs

.utilities-property-title {
  @include u-font("lang", "micro");
  @include u-margin-left(2px);
  @include u-margin-y(0);
  @include u-text("normal", "uppercase", "ls-1");
}

.utilities-property {
  @include u-bg("white");
  @include u-display("inline-block");
  @include u-margin-top(0);
  @include u-padding(0.5);
  @include u-text("no-uppercase", "ls-auto");
}

.utilities-section {
  @include u-border-top(1px);
  @include u-margin-top(4);
  @include u-padding-top(1);
}

.utilities-section-title-bar {
  @include u-flex("align-center");
  @include u-margin-bottom(2);
}

.utilities-section-title {
  @include u-font("lang", 8);
  @include u-margin(0);
  @include u-text(normal);

  code {
    @include u-bg("white");
    @include u-border(1px);
    @include u-display("inline-block");
    @include u-font("mono", 6);
    @include u-margin-right(2px);
    @include u-padding-x(0);
    @include u-padding-y(2px);
    @include u-radius("md");
    @include u-text("light");
  }

  &.usa-grid-col {
    @include grid-cols(null, "auto");
  }
}

.utilities-section-helper {
  @include u-font("lang", 1);
  @include u-margin-y(0);
  @include u-maxw("none");
  @include u-text(normal, "gray-60");

  &.usa-grid-col {
    @include grid-cols(null, "fill");
  }

  @include at-media($theme-site-cols-split) {
    @include u-text(right);
  }
}

.utility {
  @include u-bg("white");
  @include u-border(1px, "base-darker");
  @include u-margin-y(2);
  @include u-padding-bottom(2);
  @include u-padding-top(2);
  @include u-radius("md");
}

.utility-title-bar {
  @include u-margin-bottom(2);
  @include u-padding-x(2);

  & > .usa-grid-row {
    @include u-flex("align-start");
  }
}

.utility-name {
  &.usa-grid-col {
    @include grid-cols(null, "fill");
    max-width: 100%;
  }
}

.utility-title {
  @include u-font("lang", "md");
  @include u-margin-bottom(0.5);
  @include u-margin-top(0);
}

.utility-property {
  @include u-font("lang", "3xs");
  @include u-margin(0);
  @include u-text("uppercase", "ls-1");
}

.utility-property-code {
  @include u-font("mono", "2xs");
  @include u-text("light", "no-uppercase", "ls-auto", "red-warm-50v");
}

.utility-scope {
  @include add-list-reset;
  @include u-display("inline");
  @include u-margin(0);

  @include at-media($theme-site-cols-split) {
    @include u-text("right");
    max-width: 70%;
  }

  &.usa-grid-col {
    @include grid-cols(null, "auto");
  }
}

.utility-scope-button,
%utility-scope-button {
  @include u-display("inline-block");
  @include u-font-size("lang", "2xs");
  @include u-line-height("lang", 1);
  @include u-margin-right(2px);
  @include u-margin-top(0.5);
  @include u-padding(0.5);
  @include u-radius("sm");

  @include at-media("tablet") {
    @include u-font-size("lang", "xs");
    @include u-margin-right(0.5);
    @include u-margin-top(0);
    @include u-padding-y(1);
    @include u-padding-x(1.5);
    @include u-radius("md");
  }
}

.utility-scope-button-disabled {
  @extend %utility-scope-button;
  @include u-bg("white");
  @include u-border(1px, "gray-10");
  @include u-text("gray-50", strike);
}

.utility-scope-button-active {
  @include u-display(inline);

  a {
    @extend %utility-scope-button;
    @include u-bg("blue-50v");
    @include u-text("white", "no-underline");

    &:hover {
      @include u-bg("gray-80");
      @include u-text("white");
    }
  }
}

.utility-examples {
  @include u-margin-x(2);
}

.utility-examples-title {
  @include u-font("lang", 4);
  @include u-padding-top(4);
  margin-bottom: 0;
  margin-top: units(1);
  border-top: units(1px) solid color("gray-10");
}

.utility-examples-helper {
  @include u-font("lang", 2);
  @include u-margin-left(1);
  @include u-text("normal", $site-ink);
}

.utility-class,
%utility-class {
  @include u-bg("base-lightest");
  @include u-display("inline-block");
  @include u-font("mono", "2xs");
  @include u-margin(0);
  @include u-padding-y(2px);
  @include u-padding-x(0.5);
  @include u-radius("sm");
}

.utility-value {
  @extend %utility-class;
  @include u-bg("secondary-lighter");
}

.utility-value-color {
  @extend %utility-class;
  @include u-bg("white");
  @include u-border(1px, "gray-10");
  @include u-margin-top(0);
  @include u-text("light", "no-wrap", "lowercase");
}

.utility-value-color-chip {
  @include u-circle(1.5);
  @include u-display("inline-block");
  @include u-margin-right(0.5);
  @include u-text("middle");
}

.utility-example-container {
  @include u-border-top(1px, "gray-10");
  @include u-margin-top(1);
  @include u-margin-bottom(0);
  @include u-maxw(none);
  @include u-padding-top(1);
  overflow: hidden;

  & > .usa-grid-row {
    @include grid-gap("md");
    @include u-flex("row", "align-center");
  }
}

.utility-example {
  max-width: 70%;

  &.usa-grid-col {
    @include grid-col("auto");
  }
}

.utility-example-class {
  &.usa-grid-col {
    @include grid-col("fill");
  }
}

.utility-example-value {
  &.usa-grid-col {
    @include grid-col("auto");
  }
}

.utilities-output-heading {
  @include u-border-bottom(1px, "gray-10");
  @include u-font("lang", 1);
  @include u-margin-top(2);
  @include u-padding-bottom(0.5);
  @include u-text("bold");
}

.utility-code-sample {
  @include u-bg("base-lighter");
  @include u-line-height("mono", 3);
  @include u-margin-x(0);
  @include u-margin-y(1);
  @include u-radius("md");
  @include u-text("pre-wrap");
}

.utility-note {
  @include u-bg("gold-20v");
  @include u-font("lang", "xs");
  @include u-line-height("lang", 5);
  @include u-margin-y(2);
  @include u-measure(none);
  @include u-padding(2);
  @include u-radius("md");
  @include u-text("black-transparent-80");

  & + .utility-examples-title {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
  }

  a {
    color: color("ink");
  }
}

.utility-text {
  @include u-font("lang", "xs");
  @include u-line-height("lang", 5);
  @include u-measure(5);

  + .utility-text {
    @include u-margin-top(1);
  }
}

p + .usa-accordion,
h2 + div:not(.usa-accordion__content):not(.usa-collection__meta-list),
h3 + div:not(.usa-accordion__content):not(.usa-collection__meta-list),
h4 + div:not(.usa-accordion__content):not(.usa-collection__meta-list),
h5 + div:not(.usa-accordion__content):not(.usa-collection__meta-list),
h6 + div:not(.usa-accordion__content):not(.usa-collection__meta-list) {
  margin-top: units(2);
}

.sidenav a {
  padding-right: units(0.5);
}

.default-container {
  @include clearfix;
}

.site-font-lead {
  @include typeset("lang", "lg", 5);
  @include u-measure(3);
  color: color($site-ink);
  font-weight: fw("light");

  & + p {
    margin-top: units(4);
  }
}

.site-docs-card-link {
  border-top: units("05") solid color($site-base-transparent-light);
  padding-top: units("105");

  &:hover {
    @include u-border-color("primary");

    a {
      color: black;
      text-decoration-color: color("base-lighter");
    }
  }
}

.block-link::before {
  @include u-pin("all");
  content: "";
}

.usa-button-list.site-button-list {
  .usa-button {
    font-size: font-size("lang", "xs");
    margin: 0;
    width: 100%;
    @include at-media("desktop") {
      width: auto;
    }
  }

  li + li {
    .usa-button {
      margin-top: units(0.5);
      @include at-media("desktop") {
        margin-left: units(2px);
        margin-top: 0;
      }
    }
  }
}

.site-page-title,
%site-page-title {
  @include typeset("lang", "2xl", 2);
  font-weight: fw("bold");
  color: color($site-ink);
}

pre {
  white-space: pre-wrap;
}

// Responsive tables

$theme-table-responsive-expand-at: "tablet";
$theme-table-responsive-title-width: 15;
$theme-table-responsive-border-radius: "md";
$theme-table-padding-x: 2;
$theme-table-column-gap: 4;

.site-table-wrapper {
  @include at-media($theme-table-responsive-expand-at) {
    border: 1px solid;
    border-radius: radius("md");
    display: block;
    background-color: white;
  }

  .site-table-responsive {
    margin: 0;
  }

  & + .site-table-wrapper {
    margin-top: units(3);
  }
}

.site-prose .site-table-responsive,
.site-table-responsive {
  width: 100%;
  max-width: none;
  border-collapse: collapse;

  @include at-media-max($theme-table-responsive-expand-at) {
    @include typeset("lang", "2xs");
    display: block;
    width: 100%;

    caption {
      display: block;
    }

    thead {
      @include sr-only;
    }

    caption,
    tbody,
    tr,
    th,
    td,
    tfoot {
      border: 0;
      display: block;
      padding: 0;
      text-align: left;
      white-space: normal;
    }

    caption {
      font-weight: fw("bold");
      padding-bottom: units(1);
    }

    td,
    th {
      width: 100%;
      padding: units(0.5) units(0.5) units(0.5) 0;
      position: relative;
      z-index: z(100);

      &[data-title]:before {
        @include typeset("lang", 1, 2);
        @include u-padding-x(1);
        content: attr(data-title);
        flex: none;
        font-weight: fw("normal");
        margin-right: units(0.5);
        max-width: units($theme-table-responsive-title-width);
        text-align: left;
        width: 50%;
      }

      &.is-empty {
        display: none;
      }
    }

    th:not([data-title]) {
      font-weight: fw("bold");
    }

    tr {
      background-color: color("white");
      border: 1px solid;
      border-radius: radius($theme-table-responsive-border-radius);
      display: block;
      padding-bottom: units(2px);
      position: relative;

      &::after {
        @include u-pin("y");
        @include u-pin("left");
        content: "";
        background-color: color("gray-2");
        border-bottom-left-radius: radius(
          $theme-table-responsive-border-radius
        );
        border-top-left-radius: radius($theme-table-responsive-border-radius);
        display: block;
        max-width: units($theme-table-responsive-title-width);
        position: absolute;
        width: 50%;
        z-index: z(0);
      }

      &.is-empty {
        display: none;
      }

      & + tr {
        margin-top: units(1);
      }
    }

    td,
    tbody th:first-of-type {
      align-items: baseline;
      background-color: transparent;
      display: flex;
      line-height: lh("lang", 4);

      &:empty {
        display: none;
      }

      & + td {
        border-top: units(1px) solid color("base-lighter");
      }

      .site-inline-swatch {
        width: 100%;
      }
    }
  }

  @include at-media($theme-table-responsive-expand-at) {
    thead {
      @include u-font("lang", 1);
    }

    caption {
      font-weight: fw("bold");
      padding-bottom: units(1);
    }

    caption,
    tbody,
    tr,
    th,
    td,
    tfoot {
      text-align: left;
    }

    th:first-child {
      padding-left: units(2);
    }

    tr,
    td {
      background-color: transparent;
    }

    th,
    td {
      @include u-padding-x(math.div($theme-table-column-gap, 2));
      @include u-padding-y(1);
      border: none;
      text-align: left;

      &:first-child {
        padding-left: units($theme-table-padding-x);
      }

      &:last-child {
        padding-right: units($theme-table-padding-x);
      }
    }

    tbody {
      tr {
        border: none;
        border-top: units(2px) solid;

        & + tr {
          border-top: units(1px) solid color("base-lighter");
        }
      }
    }

    .site-component-usage h5 + & {
      border-top: units(2px) solid;
      margin-top: units(1);
    }
  }

  &.site-table-simple {
    p {
      margin: 0;
    }

    @include at-media($theme-table-responsive-expand-at) {
      thead {
        @include u-font("lang", 2);
      }

      th,
      td {
        @include u-padding-x(math.div($theme-table-column-gap, 2));
        @include u-padding-y(0.5);
        border: none;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }
      }

      tr {
        display: flex;

        th,
        td {
          flex: flex(1);
        }
      }

      tbody {
        tr {
          border: none;
          border-top: units(1px) solid color("base-light");

          &:last-child {
            border-bottom: units(1px) solid color("base-light");
          }
        }
      }
    }
  }
}

.site-inline-swatch {
  @include u-square(4);
  border-radius: radius("sm");
  display: inline-block;
  text-align: middle;

  table & {
    display: block;
  }
}

.site-caption-swatches {
  @include typeset("lang", 10, 2);
  color: color("ink");
  margin-top: units(2);
  margin-bottom: units(1);
  padding-left: 0;

  @include at-media($theme-table-responsive-expand-at) {
    padding-left: units(2);
  }
}

.site-table-note {
  @include typeset("lang", "2xs", 4);
  padding: units(2);
  background-color: color("gold-20v");
  border-radius: radius("md");
  margin-bottom: units(1);

  @include at-media($theme-table-responsive-expand-at) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    margin-bottom: 0;
  }
}

.site-title-short {
  @include at-media("mobile-lg") {
    display: none;
  }
}

.site-title {
  @include at-media-max("mobile-lg") {
    @include sr-only;
  }
}

.site-accordion-docs,
.site-accordion-code,
.site-accordion {
  @include typeset("lang");

  & > .usa-accordion__heading {
    @include typeset("lang", $theme-body-font-size, 1);
  }
}

%site-text-intro {
  @include typeset("lang", "lg", 5);
  @include u-measure(5);
  font-weight: fw("light");
  margin-bottom: units(2);
  margin-top: units(1);
}

.site-prose {
  @include typeset("lang");

  & > {
    p {
      line-height: lh("lang", 5);
      max-width: measure($site-measure);
    }

    p + * {
      margin-top: 1em;
    }

    h2,
    h3,
    h4,
    h5,
    h6 {
      @include typeset("lang");
      @extend %header-margin;
      color: color("ink");
      margin: 0;
    }

    h2 {
      @include typeset("lang", "lg", 2);
      @include u-text("bold");
    }

    h3 {
      @include typeset("lang", "lg", 2);
      @include u-text("normal");
    }

    h2 + h3 {
      margin-top: units(3);
    }

    h6 {
      @include typeset("lang", "2xs", 2);
      @include u-text("uppercase", "ls-2", "normal");
    }

    .site-text-intro {
      @extend %site-text-intro;

      & + p {
        margin-top: units(4);
      }
    }

    header + .site-text-intro {
      margin-top: units(1);
    }

    .usa-content-list {
      padding-left: 0;
      max-width: measure($site-measure);
    }

    li,
    ul li,
    ol li {
      line-height: lh("lang", 5);
      max-width: measure($site-measure);
    }

    blockquote {
      border-left: units(0.5) solid color("base-lighter");
      margin-left: 0;
      padding-left: units(2);
    }

    .highlighter-rouge {
      @include u-measure(5);
    }
  }
}

.site-text-intro {
  @extend %site-text-intro;
}

.usa-accordion--bordered .usa-accordion__content {
  padding-bottom: units(3);
  padding-left: units(2);
  padding-top: units(3);
}

// because any accordion__content inside of a bordered accordion get's
// borders, we need to overide for this nested component preview.
.site-component-preview
  .usa-accordion:not(.usa-accordion--bordered)
  .usa-accordion__content {
  border: none;
}

.usa-content-list {
  @include unstyled-list;
  > li {
    border-top: 1px solid color("base-lighter");
    margin-top: units(1);
    padding-top: units(1);
    max-width: measure($site-measure);

    ul {
      list-style-type: disc;
      margin-bottom: units(2);
      padding-left: units(3);
    }

    li {
      margin-top: units(0.5);
      max-width: calc(measure($site-measure) - units(3));
    }
  }

  ol {
    counter-reset: ol-counter;
    list-style: none;
    padding-left: 0;

    li {
      counter-increment: ol-counter;
      margin-top: 0.5em;
      padding-left: units(3);
      text-indent: units(-3);

      &:before {
        content: counter(ol-counter) ". ";
        display: inline-block;
        font-size: fs("lang", "3xs");
        font-weight: fw("bold");
        padding-right: units(0.5);
        text-align: left;
        text-indent: 0;
        width: units(3);
      }

      a {
        text-indent: initial;
      }
    }
  }
}

.site-accordion-heading {
  .usa-accordion__button {
    @include add-background-svg("icons/remove-white");
    background-color: color("primary-vivid");
    color: color("white");

    &:hover {
      background-color: color("primary-darker");
    }

    &[aria-expanded="false"] {
      @include add-background-svg("icons/add-white");
    }
  }
}

.site-accordion-docs,
.site-accordion {
  > .usa-accordion__button {
    @include add-background-svg("usa-icons/remove");

    &[aria-expanded="false"] {
      @include add-background-svg("usa-icons/add");
    }
  }
}

.site-component-section {
  @include typeset("lang");
  margin-top: units(5);
  max-width: none;

  > h2,
  > h3,
  > h4,
  > ul,
  > ol,
  > p {
    @include u-measure(5);
  }

  > h2 {
    @include typeset("lang", "2xl", 2);
    color: color("ink");
    margin: 0;
  }

  > h3 {
    margin-bottom: units(1);
    margin-top: units(3);

    + p {
      margin-top: 0;
    }
  }

  > h4 {
    margin-bottom: units(0.5);

    + ul {
      margin-top: units(0.5);
    }
  }

  .site-text-intro {
    @extend %site-text-intro;
  }
}

.site-component-usage {
  p + * {
    margin-top: 1em;
  }

  h2,
  h3,
  h4,
  h5,
  h5 {
    @extend %header-margin;
    color: color("ink");
    margin: 0;

    & + p {
      margin-top: units(1);
    }
  }

  &__header--secondary {
    @include typeset("lang", "lg", 2);
    color: color("base-dark");
    font-weight: fw("thin");
  }

  &__header--tertiary {
    @include typeset("lang", "md", 2);
  }
}

%header-margin {
  * + & {
    margin-top: 2em;
  }
  & + * {
    margin-top: 1em;
  }
}

.site-button-list {
  .usa-button {
    @include typeset("lang", null, 1);
  }
}

.usa-content-list {
  max-width: measure($site-measure);
}

.site-button {
  @include typeset("lang", null, 1);
  // font-weight: fw("normal");
}

.site-table,
.usa-prose > .site-table {
  @include typeset("lang");
}

.usa-table--sticky-header thead {
  @include at-media("desktop") {
    top: units($height-nav-secondary);
  }
}

.usa-accordion__button:hover {
  text-decoration: none;
}

.usa-hero__callout p {
  @include typeset("lang");
  margin-bottom: 0;
}

figure.highlight {
  margin: 0;
}

pre.lineno {
  color: color("base");
  font-weight: fw("light");
  margin-right: 2ch;
  text-align: right;
  width: 3ch;
}

#utility-mixins {
  p,
  ul {
    @include u-measure(5);
  }
}

body {
  @include u-bg($site-background-color);
  text-rendering: optimizeLegibility;
}

.site-accordion,
.site-accordion-code {
  > .usa-accordion__button {
    h2,
    h3,
    h4 {
      @include typeset("lang", "sm", 1);
      @include u-margin-y(0);
    }
  }
}

.site-note {
  @include u-bg("warning");
  @include u-measure(5);
  @include u-padding(1);
  @include u-radius("sm");

  a {
    color: color("ink");
  }
}

.site-hero {
  @include u-bg("base-lightest");
  @include u-padding-bottom(4);
  @include u-padding-x(0);
  background-image: url("../img/webp/introducing-uswds-2-0/feature-image.webp");
  background-repeat: no-repeat;
  background-position: 0 units(2);
  background-size: 100% auto;
  padding-top: 68vw;

  @include at-media("tablet") {
    @include u-padding-bottom(8);
    @include u-padding-top(6);
    background-image: url("../img/introducing-uswds-2-0/feature-image-homepage.svg");
    background-repeat: no-repeat;
    background-position: left calc(#{units("mobile-lg")} + #{units(2)}) top
      units("105");
    background-size: auto 400px;
  }

  @include at-media("tablet-lg") {
    background-position: left calc(50% + #{units("card-lg")}) top units("105");
  }
}

.page-website-standards {
  .site-subheading {
    display: none;
  }
}

.site-button-github {
  .logo {
    bottom: units(2px);
    display: inline-block;
    fill: white;
    margin-right: units(0.5);
    vertical-align: middle;
    width: units(3);

    path {
      fill: white;
    }
  }

  .text {
    bottom: units(-2px);
    position: relative;
    @include at-media("desktop") {
      @include add-sr-only;
    }
  }

  @include at-media("desktop") {
    &.usa-button {
      @include u-padding-x(0);
      background-color: color("transparent");
      display: inline-block;

      .logo {
        @include u-padding-x(0);
        bottom: units(1px);
        margin-left: units(0.5);
        position: relative;
        width: units(4);

        path {
          fill: color("white-transparent-70");
        }
      }

      &:hover,
      &:active {
        background-color: color("transparent");

        .logo {
          path {
            fill: color("white");
          }
        }
      }
    }

    .text {
      @include add-sr-only;
    }
  }
}

.embed-youtube {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.site-roadmap-list,
.site-roadmap-list__sublist {
  @include add-list-reset;
}

.site-roadmap-list {
  @include u-margin-top(2);
}

.site-roadmap-list__item,
.site-roadmap-list__subitem {
  @include u-border-top(1px);
  @include u-line-height("lang", 3);
  @include u-padding-top(0.5);
  @include u-margin-top(0.5);
  @include u-display("flex");
  @include u-flex("wrap", "align-start");
}

.site-roadmap-list__item {
  @include u-border-top("ink");
  @include u-text("bold");

  .usa-tag {
    @include u-flex("auto");
  }
}

.site-roadmap-list__sublist {
  @include u-width("full");

  .site-roadmap-list__item {
    @include u-border-top("base-lighter");
    @include u-text("normal");
    &:first-child {
      @include u-margin-top(1);
    }
    &:last-child {
      @include u-margin-bottom(2);
    }
  }
}

.site-terminal {
  pre,
  code {
    @include typeset("mono", "sm", 3);
  }
  pre {
    @include u-bg("gray-cool-80");
    @include u-measure(5);
    @include u-text("white", "pre-wrap");
    @include u-radius("md");
  }
  .nt,
  .nv,
  .nx {
    @include u-text("blue-warm-30v");
  }
  .nb {
    @include u-text("mint-30");
  }
  .c,
  .p,
  .c1 {
    @include u-text("gray-cool-30");
  }
  .nl,
  .k,
  .kd {
    @include u-text("red-warm-30v");
  }
  .bp {
    @include u-text("violet-30v");
  }
  .err,
  .cm {
    @include u-text("gray-30");
  }
}

.site-preview-heading {
  @include typeset("lang", "2xs", 2);
  @include u-margin-bottom(2);
  @include u-margin-top(4);
  @include u-text("uppercase", "ls-2", "normal", "base");
}

.site-prose {
  .site-component-section-title {
    @extend %site-h2;
    @include u-text("bold");
    border-top: units(1px) solid;
    margin-bottom: units(2);
    margin-top: units(6);
    padding-top: units(1.5);
  }

  .site-component-name {
    @extend %site-page-title;
    border-top: units(2px) solid;
    font-weight: fw("light");
    padding-top: units(2);
  }
}

.usa-content-list {
  p:first-child {
    margin-top: 0;
  }
}

.site-accordion-code {
  // Don't show margin at the top of the first form-group's label
  .usa-accordion__content > .usa-form-group:first-child,
  .usa-date-range-picker > .usa-form-group:first-child {
    .usa-label:first-child {
      margin-top: 0;
    }
  }

  // When form: Don't show margin at the top of the first form's label
  .usa-form:first-child {
    > .usa-label:first-child {
      margin-top: 0;
    }
  }
}

.site-accordion-code.site-component-preview {
  @include u-margin-top(6);

  .usa-accordion__content {
    overflow: visible;
  }
}

blockquote {
  @include u-border-left("1px", "primary");
  margin-left: 0;
  padding-left: units(2);
}

.pagination-behaviors {
  @include list-reset;
}

.pagination-behaviors--item {
  display: flex;
  flex-direction: column;
  border-top: 1px solid color("base-light");
  @include at-media("tablet") {
    flex-direction: row;
  }
}

.pagination-behaviors--guidance,
.pagination-behaviors--examples {
  @include u-flex(auto);
}

.pagination-behaviors--guidance {
  @include at-media("tablet") {
    padding-right: units(4);
    width: 60%;
  }
}

p + .pagination-behaviors--examples {
  margin-top: 0;
}

.pagination-behaviors--example {
  @include u-margin-y(1.5);
  display: block;
  width: auto;

  @include at-media("tablet") {
    height: units(4);
  }
}

.site-identifier {
  .usa-identifier__required-link {
    display: block;
  }
}

.table-example-container {
  display: flex;
  column-gap: 2rem;
  row-gap: 2rem;
  flex-flow: row wrap;
  justify-content: space-around;
}

.language-diff {
  .err {
    color: color("gray-60");
  }
  .p {
    color: color("ink");
  }
  .gi {
    color: color("green-60v");
  }
  .gd {
    color: color("red-60v");
  }
}

// Do/Don't Guidance Section

$do-dont-color-do: "green-cool-50v";
$do-dont-color-dont: "red-cool-50v";
$do-dont-color-border: "gray-cool-20";
$do-dont-padding: 3;
$do-dont-top-bar-width: 1;

.do-dont {
  display: flex;
  height: 100%;
  padding-top: units(2);
}

.do-dont__do,
.do-dont__dont {
  background-color: color("white");
  border: 1px solid color($do-dont-color-border);
  flex: 1 0 0;
  height: 100%;
  position: relative;
}

.do-dont__do {
  padding: 0rem;
}

.do-dont__do:before,
.do-dont__dont:before {
  content: "";
  position: absolute;
  height: units($do-dont-top-bar-width);
  width: calc(100% + 2px);
  top: -1px;
  left: -1px;
}

.do-dont__do:before {
  background-color: color($do-dont-color-do);
}

.do-dont__dont:before {
  background-color: color($do-dont-color-dont);
}

.do-dont__heading {
  @include typeset("lang", "lg", 2);
  align-items: center;
  border-bottom: 1px solid color($do-dont-color-border);
  display: flex;
  font-weight: fw("bold");
  margin: 0;
  padding: units(4) units($do-dont-padding) units($do-dont-padding);

  .usa-icon {
    @include u-square(3);
    margin-right: units(0.5);
  }
}

.do-dont__do .do-dont__heading {
  color: color($do-dont-color-do);
}

.do-dont__dont .do-dont__heading {
  color: color($do-dont-color-dont);
}

.do-dont__content {
  padding: 0 units($do-dont-padding) units($do-dont-padding) 0;

  ul {
    margin-bottom: 0;
    overflow-wrap: anywhere;
  }

  li + li {
    margin-top: units(1);
  }
}

// Pattern landing page
.pattern-card-heading {
  font-weight: fw("normal");
}

// Memorable Date w/select
.usa-form-group--month {
  width: units(card-lg);
}
.usa-in-page-nav {
  .usa-in-page-nav__heading {
    @include typeset("lang", "2xs", 3);
  }
  .usa-in-page-nav__list {
    .usa-in-page-nav__item {
      @include typeset("lang", "3xs", 2);
    }
  }
}

.site-story {
  @include typeset("lang", "xl", 2);
  @include u-text("normal");
}
.site-changelog--show-tablet {
  @include at-media-max("tablet") {
    display: none;
  }
  @include at-media("widescreen") {
    display: none;
  }
}

.site-changelog--hide-tablet {
  @include at-media("tablet") {
    display: none;
  }
  @include at-media("widescreen") {
    display: block;
  }
}

.usa-in-page-nav {
  @include u-z(100);
}

// Accessibility checklist styles
.usa-prose .site-checklist-test-summary {
  @include u-bg("gray-5");
  @include u-margin-top(6);
  @include u-measure(6);
  @include u-padding-x(3);
  @include u-padding-y(1);

  &__table {
    @include u-display("none");
    @include u-font("lang", "3xs");
    @include u-text("center");

    @include at-media("desktop-lg") {
      @include u-display("block");
    }

    @include at-media("widescreen") {
      @include u-font("lang", "2xs");
    }

    caption {
      @include u-font("lang", "xs");
    }

    th,
    td {
      @include u-border("gray-30");
      @include u-padding-left(1);
      @include u-padding-right("105");

      @include at-media("widescreen") {
        @include u-padding-x(2);
      }
    }

    td {
      @include u-text("bold");
    }

    th,
    td:first-of-type {
      @include u-bg("gray-10");
    }

    .usa-icon {
      @include u-margin("05");
      @include u-minh("205");
      @include u-minw("205");
    }
  }

  &__list {
    @include u-margin(0);
    @include u-padding-bottom(2);
    @include u-padding-top(1);

    @include at-media("desktop-lg") {
      @include u-display("none");
    }

    &-summary {
      @include u-font("lang", "xs");
      @include u-margin-y(1);
      @include u-text("bold");
    }

    .usa-icon-list {
      @include u-margin-top(2);
    }

    .usa-icon-list__content {
      @include u-font("lang", "sm");
      @include u-line-height("lang", 5);
    }
  }
}

.site-checklist-intro-grid {
  @include u-margin-top(2);
  @include u-margin-bottom(4);
  @include u-maxw("mobile-lg");

  @include at-media("desktop") {
    @include u-display("flex");
    @include u-measure(6);
  }

  &__grid-item {
    @include u-display("flex");
    @include u-border("1px", "gray-10");
    @include u-border-bottom(1);
    @include u-margin-top(2);
    @include u-padding(2);

    @include at-media("desktop") {
      @include grid-col;
      @include u-margin-top(0);
    }

    &:nth-of-type(odd) {
      @include at-media("desktop") {
        @include u-margin-right(2);
      }
    }
  }

  &__summary {
    @include u-text("bold");
    @include u-margin-y(1);
  }

  &__details {
    @include u-font("lang", "xs");
    @include u-line-height("lang", 5);
    @include u-margin-y(0);
  }

  .usa-icon {
    @include u-margin-right(1);
    @include u-minh(5);
    @include u-minw(5);
  }
}

.usa-prose .site-checklist-jump-links {
  @include u-padding-left(0);
  list-style-type: none;

  li {
    @include u-margin-bottom(0);
  }

  a {
    @include u-display("flex");
    @include u-flex("align-center");
    @include u-margin-right(2);
    @include u-padding-y("05");
    @include u-text("primary-dark");
    @include u-text("bold");
    @include u-text("no-underline");

    &:visited {
      color: color("primary-dark");
    }
  }

  .usa-icon {
    @include u-margin-right(1);
  }
}

.site-checklist-accordion {
  @include u-margin-bottom(2);
  @include u-measure(5);

  .usa-accordion__button,
  .usa-accordion__content {
    @include u-font("lang", "sm");
  }

  .usa-accordion__content {
    @include u-border("1px", "gray-5");
    border-top: none;
  }
}

.site-checklist-fieldset {
  @include u-margin-top(1);
  @include u-measure(5);

  @include at-media("widescreen") {
    @include u-maxw("tablet-lg");
  }

  .usa-checkbox {
    @include u-border-top("1px", "gray-10");
    @include u-margin-top(1);
    background-color: unset;
  }

  .usa-checkbox__label {
    @include u-margin-bottom(1);
    @include u-display("flex");
    @include u-flex("justify");
    padding-left: 1.75rem;
  }

  .usa-checkbox__label::before {
    box-shadow: color("gray-70");
    height: 0.9rem;
    width: 0.9rem;
    margin-top: 0.35rem;
  }

  &__text {
    @include u-font("lang", "sm");
    @include u-margin-bottom(1);
    @include u-margin-top(0);
    @include u-line-height("sans", 6);

    &--status {
      @include u-margin-y(1);
      @include u-font("lang", "xs");
      @include u-line-height("lang", 4);
    }

    &--pass,
    &--pass a,
    &--pass a:visited {
      color: color("green-cool-60");
    }

    &--exception,
    &--exception a,
    &--exception a:visited {
      color: color("orange-70v");
    }

    &--fail,
    &--fail a,
    &--fail a:visited {
      color: color("red-70v");
    }

    &--conditional,
    &--conditional a,
    &--conditional a:visited {
      color: color("blue-cool-60");
    }
  }
}

.site-checklist-tag-list {
  @include u-padding-left(0);
  @include u-margin-bottom(0);
  list-style-type: none;

  &--main {
    @include u-display("flex");
    @include u-flex("align-center");
    @include u-flex("row");
    @include u-flex("wrap");

    .site-checklist-tag {
      @include u-flex("align-center");
    }
  }

  &--aside {
    @include u-display("none");
    @include u-flex("align-end");
    @include u-flex("justify-start");
    @include u-flex("column");
    @include u-margin-top("05");

    @include at-media("widescreen") {
      @include u-display("flex");
    }

    .site-checklist-tag {
      @include u-text("right");
    }

    .site-checklist-tag__icon-text {
      @include u-display("flex");
      @include u-flex("align-center");
      @include u-flex("justify-end");
    }
  }
}

.site-checklist-tag {
  @include u-border("1px", "gray-10");
  @include u-font("lang", "3xs");
  @include u-line-height("lang", 4);
  @include u-margin-bottom(1);
  @include u-margin-right(1);
  @include u-padding-y("05");
  @include u-padding-x(1);
  @include u-radius("md");

  // Safari fix
  a {
    display: flex !important;
  }

  &--pass {
    border-color: color("green-cool-10");
    background-color: color("green-cool-10");
  }

  &--exception {
    border-color: color("yellow-10");
    background-color: color("yellow-10");
  }

  &--fail {
    border-color: color("red-20v");
    background-color: color("red-20v");
  }

  &--conditional {
    border-color: color("blue-cool-10");
    background-color: color("blue-cool-10");
  }

  &--hide-wide {
    @include u-display("flex");

    @include at-media("widescreen") {
      @include u-display("none");
    }
  }
}

.site-checklist-icon {
  @include u-height(2);
  @include u-width(2);
  @include u-margin-right("05");

  &--pass {
    color: color("green-cool-50v");
  }

  &--exception {
    color: color("gold-30v");
  }

  &--fail {
    color: color("orange-warm-60v");
  }

  &--conditional {
    color: color("blue-cool-60");
  }
}

.site-compliance-tag {
  @include u-font("lang", "xs");
  @include u-text("no-uppercase");
  @include u-padding-x("105");
  @include u-padding-y(1);

  &--pass {
    background-color: color("green-cool-50v");
  }

  &--fail {
    background-color: color("red-50");
  }
}

$proposal-phase-color: color("yellow-20v");
$accepted-phase-color: color("yellow-20v");
$rejected-phase-color: color("gray-cool-60");
$development-phase-color: color("blue-20v");
$experimental-phase-color: color("mint-20");
$released-phase-color: color("mint-50");
$caution-phase-color: color("orange-30v");
$deprecated-phase-color: color("red-warm-60v");

.lifecycle {
  &-border-bottom {
    border-bottom: units(1) solid;
  }

  &-border--proposal {
    border-color: $proposal-phase-color !important;
  }

  &-border--development {
    border-color: $development-phase-color !important;
  }

  &-border--released {
    border-color: $released-phase-color !important;
  }

  &-border--deprecated {
    border-color: $deprecated-phase-color !important;
  }

  &-bg--proposal {
    background-color: $proposal-phase-color !important;
  }

  &-bg--development {
    background-color: $development-phase-color !important;
  }

  &-bg--released {
    background-color: $released-phase-color !important;
    color: white !important;
  }

  &-bg--experimental {
    background-color: $experimental-phase-color !important;
  }

  &-bg--caution {
    background-color: $caution-phase-color !important;
  }

  &-bg--deprecated {
    background-color: $deprecated-phase-color !important;
    color: white !important;
  }

  &-bg--accepted {
    background-color: $accepted-phase-color !important;
  }

  &-bg--rejected {
    background-color: $rejected-phase-color !important;
    color: white !important;
  }
}

.lifecycle-process {
  .usa-process-list__item:last-child {
    padding-bottom: units(1);
  }
}

.lifecycle-phase {
  @include u-measure(6);

  &__intro {
    @include at-media("desktop-lg") {
      @include grid-col(3);
      @include u-margin-right(4);
    }
  }

  &__heading {
    @include u-line-height("sans", 1);
    @include u-margin-top(0);
    @include u-margin-bottom(1);
  }

  &__description {
    @include u-margin-top(0);
  }

  &__subphase {
    @include u-margin-bottom(4);
    @include u-measure(5);

    p {
      @include u-margin-y("105");
    }

    ul,
    li {
      @include u-line-height("sans", 4);
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  &__subphase-header {
    @include u-display("inline-block");
    @include u-margin-bottom(0);
    @include u-padding-y("05");
    border-bottom: 4px solid color("gray-30");

    &:first-of-type {
      margin-top: 0;
    }
  }
}

.usa-prose > .lifecycle-table {
  @include u-font("lang", "3xs");
  @include u-margin-top(4);
  @include u-maxw("tablet-lg");
  @include u-width("full");

  thead {
    @include u-text("bold");
  }

  tr {
    border: units("05") solid color($site-background-color) !important;
  }

  td,
  thead th {
    @include u-padding-y("05");
    @include u-bg($site-background-color);
    border: none;
    text-align: center;
    width: 20%;
  }

  td:first-of-type {
    @include u-maxw(15);
    @include u-padding-left(0);
    text-align: left;
  }
}

.usa-alert.site-alert {
  .usa-alert__body {
    @include typeset("lang");
  }

  .usa-alert__heading {
    @include typeset("lang", "lg", 1);
  }
}

@include override-prose {
  .usa-icon-list.site-icon-list {
    @include typeset("lang", $theme-body-font-size);
  }
}

.usa-icon-list.site-icon-list {
  .usa-icon-list__content {
    @include u-font("lang", "sm");
    @include u-line-height("lang", 5);
  }

  .usa-icon-list__content {
    > p,
    > ul,
    > ol {
      @include u-font("lang", $theme-body-font-size);
      @include u-measure(5);
    }
  }
}

@include override-prose {
  .usa-collection.site-collection {
    @include typeset("lang", "sm", 3);
  }
}

.usa-collection.site-collection {
  .usa-collection__heading {
    @include typeset("lang", "md", 3);
  }
}
