.card-policy {
  @include u-margin-y(2);
  @include u-maxw("full");

  button {
    @include typeset("lang", "xs", 3);
    @include u-padding-y("105");
    @include u-padding-left(7);
    @include u-padding-right(5);
    @include u-text("medium");
    @include u-text("gold-80");
    @include u-position("relative");
    @include u-bg("gold-10");
    @include u-radius("sm");
    @include at-media("tablet") {
      @include u-padding-right(7);
    }

    .scroll {
      @include u-padding-x(1);
      @include u-position("absolute");
      @include u-top(0);
      @include u-left(0);
      @include u-height("full");
      @include u-display("flex");
      @include u-flex("align-center");
      @include u-text("white");
      @include u-bg("gold-50");
      @include u-radius-left("sm");
    }

    .src {
      @include u-text("medium");

      em {
        @include u-margin-left("2px");
        @include u-text("base-dark");
        @include u-text("italic");
        @include u-text("normal");
      }

      strong {
        @include u-text("heavy");
      }

      .kicker {
        @include u-margin-bottom("05");
        @include u-display("block");
        @include u-text("uppercase");
        @include u-text("light");
        @include u-font("lang", "3xs");
      }
    }

    &:hover {
      @include u-bg("gold-20");

      .scroll {
        @include u-bg("gold-60");
      }
    }
  }

  .card-policy-body {
    @include u-margin-top(0);
    @include u-padding-y(4);
    @include u-padding-x(4);
    @include u-padding-left(7);
    @include at-media("tablet") {
      @include u-padding-left(7);
    }
    @include u-bg("gold-5");
    @include u-text("gold-80");
    @include u-radius-bottom("sm");
    @include u-font("serif", "2xs");
    @include u-line-height("serif", 5);

    blockquote {
      @include u-margin-x(0);
      @include u-padding(0);
      @include u-padding-left(2);
      @include u-border-left("2px", "base", "solid");
      @include u-text("base");
      @include u-bg("gold-5");
    }

    .src {
      @include u-margin-top(3);
      @include u-font("lang", "xs");
      @include u-text("gold-80");
      @include u-text("medium");
      @include u-display("block");
    }
  }
}
